
    import gsap from 'gsap';
    import MenuBar from '@components/MenuBar.vue';
    import PromoLinks from '@components/PromoLinks.vue';
    import TheFooter from '@components/TheFooter.vue';

    export default {
        components: {
            TheFooter,
            MenuBar,
            PromoLinks,
        },

        data () {
            return {
                mounted: false,
            };
        },

        mounted () {
            if (this.$route.path === '/landing') {
                this.$refs.app.style.position = 'fixed';
                this.$animationEffects.fadeIn([this.$refs.headerLockup]);
                gsap.delayedCall(1.75, this.moveLockup);
            }
            else {
                this.mounted = true;
            }
        },

        updated () {
            if (this.mounted) this.fadeInFooter();
        },

        methods: {
            moveLockup () {
                this.mounted = true;
                this.fadeInFooter();
                this.$refs.app.style.position = 'relative';
            },

            fadeInFooter () {
                this.$animationEffects.fadeIn([this.$refs.footer.$el]);
            },
        },

        i18nOptions: { namespaces: ['global'] },
    };
