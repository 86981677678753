
    import { mapActions } from 'vuex';
    import MenuBar from '@components/MenuBar.vue';
    import PromoLinks from '@components/PromoLinks.vue';

    export default {
        components: {
            MenuBar,
            PromoLinks,
        },

        methods: {
            ...mapActions({
                logOut: 'profile/logOut',
            }),
        },

        i18nOptions: { namespaces: ['global'] },
    };
