import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href", "target", "data-e2e"]
const _hoisted_2 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("a", {
    href: $options.href,
    target: $props.target,
    class: _normalizeClass(`${$props.affiliateId}-affiliate`),
    "data-e2e": `${$props.affiliateId}-affiliate-link`,
    onClick: _cache[0] || (_cache[0] = (...args) => ($options.trackAndGo && $options.trackAndGo(...args)))
  }, [
    _renderSlot(_ctx.$slots, "default", {}, () => [
      _createElementVNode("span", {
        innerHTML: _ctx.$t(`buttons:affiliates.${$props.affiliateId}`) || `Go to ${$props.affiliateId}`
      }, null, 8, _hoisted_2)
    ])
  ], 10, _hoisted_1))
}