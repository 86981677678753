
    import { mapState, mapGetters, mapActions } from 'vuex';
    import { openPopup } from '../popup';
    import PromoLinks from './PromoLinks.vue';

    export default {
        name: 'TheFooter',
        components: {
            PromoLinks,
        },
        i18nOptions: { namespaces: ['footer', 'links', 'global'] },
        props: {
            hidelinks: {
                type: String,
                default: '',
            },
        },
        computed: {
            ...mapState([
                'app',
                'profile',
                'ui',
            ]),
            ...mapGetters({
                loggedIn: 'profile/loggedIn',
            }),
        },

        methods: {
            openPopup,

            ...mapActions({
                logOut: 'profile/logOut',
            }),
        },
    };

