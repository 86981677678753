import { ProfileLayout } from '../layouts';

// Asynchronous component definitions for lazy-loading routes.
// https://router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments
const
    ForgotPasswordPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/ForgotPasswordPage.vue'
    ),
    ForgotPasswordConfirmPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/ForgotPasswordConfirmPage.vue'
    ),
    ResetPasswordPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/ResetPasswordPage.vue'
    ),
    ResetPasswordSuccessPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/ResetPasswordSuccessPage.vue'
    ),
    ResetPasswordErrorPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/ResetPasswordErrorPage.vue'
    );

export default [
    {
        path: '/forgot_password',
        component: ForgotPasswordPage,
        meta: {
            public: true,
            layout: ProfileLayout,
        },
    },
    {
        path: '/forgot_password_confirm',
        component: ForgotPasswordConfirmPage,
        meta: {
            public: true,
            layout: ProfileLayout,
        },
    },
    {
        path: '/reset_password',
        component: ResetPasswordPage,
        meta: {
            public: true,
            layout: ProfileLayout,
        },
    },
    {
        path: '/reset_password_success',
        component: ResetPasswordSuccessPage,
        meta: {
            public: true,
            layout: ProfileLayout,
        },
    },
    {
        path: '/reset_password_error',
        component: ResetPasswordErrorPage,
        meta: {
            public: true,
            layout: ProfileLayout,
        },
    },
];
