
    import { mapActions } from 'vuex';
    import { checkFeatureFlag } from '../feature-flags';

    export default {
        name: 'OutgoingAffiliate',

        props: {
            affiliateId: {
                type: String,
                required: true,
            },
            target: {
                type: String,
                default: null,
            },
        },

        computed: {
            href () {
                return `/redirect_to_affiliate?affiliate_id=${this.affiliateId}`;
            },
        },

        methods: {
            ...mapActions({
                'pollNotifications': 'notifications/pollNotifications',
                'recordEvent': 'profile/recordEvent',
            }),
            checkFeatureFlag,
            async trackAndGo (event) {
                const
                    { affiliateId } = this,
                    { target } = this,
                    { href } = this,
                    rewardsclick = ['continue', 'ddperks', 'findstore'];

                if (rewardsclick.includes(affiliateId) > -1) {
                    try {
                        await this.recordEvent('click_ddlink');
                        if (this.checkFeatureFlag('notifications')) this.pollNotifications();
                    }
                    catch (err) {
                        console.error(err);
                    }
                }
                // Allow default behavior when we are opening in a new window.
                // This happens when target is `_blank`, or the user presses
                // CTRL+click, CMD+click, or middle click.
                if (
                    target === '_blank' ||
                    event.ctrlKey || event.meta || event.which == 2
                ) {
                    this.$webAnalytics.track('Exit', { category: 'Affiliate', label: affiliateId, value: 1 });
                    return true;
                }
                else {
                    this.$webAnalytics.track('Exit', { category: 'Affiliate', label: affiliateId, value: 1, callback: () => {
                        switch (target) {
                            case '_top':
                                window.top.location.href = href;
                                break;
                            case '_parent':
                                window.parent.location.href = href;
                                break;
                            default:
                                window.location.href = href;
                        }
                    } });
                    event.preventDefault();
                    return false;
                }
            },
        },
    };
