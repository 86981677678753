import { BlankLayout } from '../layouts';

// Asynchronous component definitions for lazy-loading routes.
// https://router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments
const
    FaqPage = async () => import(
        /* webpackChunkName: 'faq' */
        '../views/FaqPage.vue'
    ),
    ReceiptUploadTips = async () => import(
        /* webpackChunkName: 'ReceiptUploadTips' */
        '../views/ReceiptUploadTips.vue'
    ),
    TermsPage = async () => import(
        /* webpackChunkName: 'terms' */
        '../views/TermsPage.vue'
    );

export default [
    {
        path: '/faq',
        component: FaqPage,
        meta: {
            public: true,
            activeInPhase: ':any',
            activeInMaint: true,
            layout: BlankLayout,
        },
    },
    {
        path: '/receipt_upload_tips',
        component: ReceiptUploadTips,
        meta: {
            public: true,
            layout: BlankLayout,
        },
    },
    {
        path: '/terms',
        component: TermsPage,
        meta: {
            public: true,
            layout: BlankLayout,
        },
    },
];
