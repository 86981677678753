
    export default {
        props: {
            id: {
                required: true,
                type: [String, Number],
            },
        },

        i18nOptions: { namespaces: ['rewards_catalog'] },
    };
