import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "page-error" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["href", "title", "textContent"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", {
      innerHTML: _ctx.$t('headline_copy')
    }, null, 8, _hoisted_2),
    _createElementVNode("p", {
      innerHTML: _ctx.$t('body_copy.' + $options.finalType)
    }, null, 8, _hoisted_3),
    (_ctx.$t('button.' + $options.finalType))
      ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          class: "button",
          href: _ctx.$t(`button.${$options.finalType}.url`),
          title: _ctx.$t(`button.${$options.finalType}.text`),
          textContent: _toDisplayString(_ctx.$t(`button.${$options.finalType}.text`))
        }, null, 8, _hoisted_4))
      : _createCommentVNode("", true)
  ]))
}