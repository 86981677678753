
import { checkFeatureFlag } from './feature-flags';
import openModal, { Terms4xBonusingModal, PunchcardPromoModal } from './modals';
import { openPopup } from './popup';

export function filterDrawerData (data) {
    return data.filter(d => !d.hideClientSide &&
        (d.feature_flags ? d.feature_flags.some(f => checkFeatureFlag(f)) : true
        ));

    // return data.filter(d => {
    //     console.log(d);
    //     if(d.feature_flags) {
    //         return d.feature_flags.some(f => { console.log(f, checkFeatureFlag(f)); return checkFeatureFlag(f);})
    //     }
    //     else {
    //         return true;
    //     }
    // })
}

export function modalLinkHandler (e) {
    const library = {
        Terms4xBonusingModal,
        PunchcardPromoModal,
    };
    if (e.target.dataset.modal) {
        e.preventDefault();
        openModal(library[e.target.dataset.modal]);
    }
}

export function popupLinkHandler (e) {
    if (e.target.classList.contains('popup-link')) {
        e.preventDefault();
        openPopup(e);
    }
}
