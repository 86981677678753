
import axios from 'axios';
import { reactive } from 'vue';

import api from '../../api';

const getDefaultState = () => reactive({
    punchcards: [],
});

const state = getDefaultState();

const getters = {};

const mutations = {
    updatePunchcardStatus (state, dataObject) {
        for (const key in dataObject) {
            state[key] = dataObject[key];
        }
    },
};

const actions = {
    async getPunchcardStatus ({ commit }) {
        try {
            const response = await axios.get(`${api.base}/loyalty/user/me/punchcard`);

            commit('updatePunchcardStatus', response.data);
            return response.data;
        }
        catch (error) {
            console.error('failed to load punchcard status', error);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
