import gsap from 'gsap';

export default {
    install (app) {
        app.config.globalProperties.$animationEffects = {
            fadeIn,
            setPageHeight,
        };
    },
};

/**
 * @function fadeIn
 * @param {string[]} items - A array of elements to fade in
 */
export function fadeIn (items) {
    items.forEach((item, index) => {
        gsap.set(item, {
            y: '50px',
            opacity: 0,
            visibility: 'visible',
        });

        gsap.to(item, {
            duration: 0.5,
            delay: 0.1 * index,
            opacity: 1,
            y: 0,
            ease: 'circ.out',
        });
    });
}

/**
 * @function setPageHeight
 * @description Resizes the hub page so that the content is not hidden under the drawer
 */
export function setPageHeight () {
    const hubPage = document.getElementsByClassName('page-hub')[0];
    const drawer = document.getElementById('drawer');

    hubPage.style.height = `${(drawer.offsetHeight + hubPage.offsetHeight + 40) / 16}rem`;
}
