
import axios from 'axios';
import { reactive } from 'vue';

import api from '../../api';

const getDefaultState = () => reactive({
    transactions: [],
});

const state = getDefaultState();

const getters = {};

const mutations = {
    updateTransactionHistory (state, data) {
        state.transactions = [...data];
    },
};

const actions = {
    async getTransactionHistory ({ commit }) {
        try {
            const { data: { history = [] } } = await axios.get(`${api.base}/loyalty/user/me/transaction-history`);

            commit('updateTransactionHistory', history);
        }
        catch (error) {
            console.error('failed to load transaction history', error);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
