
const getDefaultState = () => ({

});

const state = getDefaultState();

const getters = {
};

const mutations = {
    clear (state, actionName) {
        state[actionName] = false;
    },
    createOrUpdate (state, { actionName, actionProps }) {
        if (actionProps === undefined) {
            state[actionName] = true;
        }
        else {
            state[actionName] = actionProps;
        }
    },
};

const actions = {
    turnOffAction ({ commit }, actionName) {
        commit('clear', actionName);
    },
    turnOnAction ({ commit }, actionData) {
        if (typeof actionData === 'string') {
            commit('createOrUpdate', { actionName: actionData });
        }
        else {
            const actionName = actionData.action;
            const  actionProps = actionData.props;
            commit('createOrUpdate', { actionName, actionProps });
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
