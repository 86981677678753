
/* This is client-side code. */
/* eslint-env browser */

const
    { isFilled } = require('rocketship-validator'),
    base = require('./validators');

module.exports = {
    register: {
        ...base.register,
        'g-recaptcha-response': { requireCaptcha },
        'g-recaptcha-response-v3': { requireCaptcha },
    },
    login: base.login,
    landing: base.landing,
    viral: base.viral,
    faqContact: base.faqContact,
    birthDate: base.birthDate,
    passwordReset: base.passwordReset,
    overlayRules: base.overlayRules,
    redeem: {
        ...base.redeem,
        'g-recaptcha-response-v3': { requireCaptcha },
    },
};

function requireCaptcha (value, field, profile) {
    if (!isFilled(value)) {
        // Error out early if we don't have a response.
        this.addError(field, 'NULL');
        return false;
    }
    return true;
}
