import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view")
  const _component_CookieGate = _resolveComponent("CookieGate")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$route.meta.layout || 'div'), null, {
      default: _withCtx(() => [
        _createVNode(_component_router_view, { class: "content" })
      ]),
      _: 1
    })),
    _createVNode(_component_CookieGate, {
      "body-copy": _ctx.$t('cookie_gate:body_copy'),
      "button-copy": _ctx.$t('cookie_gate:button_copy'),
      "title-copy": _ctx.$t('cookie_gate:title')
    }, null, 8, ["body-copy", "button-copy", "title-copy"])
  ], 64))
}