
    import BaseModal from '../BaseModal';
    import { checkFeatureFlag } from '../../feature-flags';

    export default {
        name: 'SweepstakeConfirmationModal',

        extends: BaseModal,

        props: {
            apiKey: {
                type: String,
                required: true,
            },
            sweepstakeConfirmationCopy: {
                type: String,
                required: true,
            },
        },

        methods: {
            checkFeatureFlag,
            exitToOpenRedeem () {
                this.closeModal('openRedeem');
            },
            exitToFileUpload () {
                this.closeModal('fileUpload');
            },
            exitToPlay () {
                this.closeModal('playHH');
            },
        },

        i18nOptions: { namespaces: ['modal_content'] },
    };
