import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "modal reward-redemption-confirmation-modal",
  role: "dialog",
  "aria-labelledby": "modal-title"
}
const _hoisted_2 = { id: "modal-title" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "disclaimer" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_GiftCardRewardLockup = _resolveComponent("GiftCardRewardLockup")

  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay",
    onClick: _cache[3] || (_cache[3] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t('confirmation.headline_copy')), 1),
      _createVNode(_component_GiftCardRewardLockup, {
        id: $props.reward.copy_deck_node
      }, null, 8, ["id"]),
      _createElementVNode("p", {
        innerHTML: _ctx.$t('confirmation.body_copy', { points: $props.reward.points, cost: _ctx.$t(`reward_values.${$props.reward.id}`) })
      }, null, 8, _hoisted_3),
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = $event => ($options.redeem()))
      }, _toDisplayString(_ctx.$t('buttons:redeem')), 1),
      _createElementVNode("a", {
        href: "",
        class: "go-back",
        onClick: _cache[1] || (_cache[1] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, _toDisplayString(_ctx.$t('buttons:go_back')), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('disclaimer')), 1),
      _createElementVNode("a", {
        href: "#",
        class: "close-btn",
        title: "Close modal",
        onClick: _cache[2] || (_cache[2] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, _cache[4] || (_cache[4] = [
        _createElementVNode("i", {
          "aria-hidden": "true",
          class: "fa-solid fa-xmark"
        }, null, -1)
      ]))
    ])
  ]))
}