
    import { mapActions } from 'vuex';
    import GiftCardRewardLockup from '@components/GiftCardRewardLockup.vue';
    import BaseModal from './BaseModal';

    const newMemberConfig = {"_public":true,"messages":{"newMember23":{"value":5,"displayMax":3}}};

    export default {
        name: 'RewardRedemptionConfirmationModal',

        components: {
            GiftCardRewardLockup,
        },

        extends: BaseModal,

        props: {
            id: {
                type: String,
                required: true,
            },
        },

        methods: {
            ...mapActions({
                getDrawerData: 'drawer/getDrawerData',
                redeemReward: 'profile/redeemReward',
            }),

        },

        computed: {
            copyNodeName () {
                return `modal_content:new_member_modal.${this.id}`;
            },
            cardValue () {
                return newMemberConfig.messages[this.id].value;
            },
        },

        i18nOptions: { namespaces: ['modal_content'] },
    };
