
import axios from 'axios';
import { reactive } from 'vue';
import config from 'rocketship-config';
import api from '../../api';

const SESSION_PERSISTENCE_ENABLED = true;
const sessionStorageKey = 'hw-auth';

const getDefaultState = () => reactive({
    token: getLocalStorageToken(),
});

const state = getDefaultState();

const getters = {};

const mutations = {
};

const cleanProfile = (profile) => {
    if (Object.prototype.hasOwnProperty.call(profile, 'g-recaptcha-response')) {
        // Remove from returned state
        delete profile['g-recaptcha-response'];
    }
    // Remove empty token to prevent overwriting a valid one.
    if (Object.prototype.hasOwnProperty.call(profile, 'token') && !profile.token) {
        delete profile.token;
    }

    return profile;
};

const actions = {
    async overlayOptIn ({ state, commit }, { apiKey, rules }) {
        try {
            const { data } = await axios.post(`${api.base}/overlays/${apiKey}/opt-in`, { rules });
            const profile = cleanProfile(data?.profile);
            commit('profile/updateProfile', profile, { root: true });
        }
        catch (err) {
            console.error(err);

            throw err;
        }
    },
};

function getLocalStorageToken () {
    if (!SESSION_PERSISTENCE_ENABLED) return;

    try { return window.localStorage.getItem(sessionStorageKey); }
    catch (err) { console.error('localStorage error', err); }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
