
    import BaseModal from '@public/js/modals/BaseModal';

    export default {
        name: 'ExitToPromoModal',

        extends: BaseModal,

        props: {
            apiKey: {
                type: String,
                required: true,
            },
        },

        data () {
            return {
                modalName: 'exitToFallFestival',
            };
        },

        methods: {
            buttonContinue () {
                this.$webAnalytics.track('Exit', { category: 'Affiliate', label: 'button_accept', value: '', callback: () => {
                    window.location.href = `/sso_redirect?target=${this.apiKey}`;
                } });
            },

            buttonClose () {
                this.$webAnalytics.track('Click', { cateogry: 'GoTo', label: 'button_cancel',  value: '', callback: () => {
                    this.closeModal();
                } });
            },
        },

        i18nOptions: { namespaces: ['modal_content'] },
    };
