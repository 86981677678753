
    import { mapState } from 'vuex';
    import MenuBar from '@components/MenuBar.vue';
    import PromoLinks from '@components/PromoLinks.vue';
    import TheFooter from '@components/TheFooter.vue';
    import { checkFeatureFlag } from '../feature-flags';

    export default {
        components: {
            TheFooter,
            MenuBar,
            PromoLinks,
        },
        computed: {
            ...mapState(['app']),
        },
        methods: {
            checkFeatureFlag,
            buildDynamicHref (target) {
                if (!checkFeatureFlag('oktaAuth0')) return `#/${target}`;

                const authPage = target === 'register' ? 'signup' : 'login';
                return `oauth/authenticate?target=${authPage}`;
            },
        },


        i18nOptions: { namespaces: ['global', 'landing'] },
    };
