import { DefaultLayout } from '../layouts';

// Asynchronous component definitions for lazy-loading routes.
// https://router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments
const
    MaintPage = async () => import(
        /* webpackChunkName: 'maint' */
        '../views/MaintPage.vue'
    );

export default [
    { path: '/maintenance', alias: '/', component: MaintPage, meta: {
        public: true,
        activeInPhase: ':any',
        activeInMaint: true,
        layout: DefaultLayout,
    } },
];
