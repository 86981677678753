import axios from 'axios';
import api from '../../api';
import { checkFeatureFlag } from '../../feature-flags';

const POINTS = 'points';
const RECEIPT_APPROVED = 'receipt_approved';
const RECEIPT_PENDING = 'receipt_pending';
const PURCHASE = 'purchase';

const state = {
    new_content_indicators: {
        eligibleProducts: [],
        extras: [],
        restaurants: [],
    },
    modals: {
    },
    // Keep for testing purposes
    // 0: {
    //     created: '2022-06-16T23:56:57.000Z',
    //     data: { points: 10 },
    //     id: '11ecedae7a9b8e30c8fc5ca2897d76f6',
    //     type: 'points',
    // },
    // 1: {
    //     created: '2022-06-16T23:56:57.000Z',
    //     data: null,
    //     id: '11ecedae7a9b8e30c8fc5ca2897d76f7',
    //     type: 'receipt_approved',
    // },
    // 2: {
    //     created: '2022-06-16T23:56:57.000Z',
    //     data: null,
    //     id: '11ecedae7a9b8e30c8fc5ca2897d76f8',
    //     type: 'receipt_approved',
    // },
    // 3: {
    //     created: '2022-06-16T23:56:57.000Z',
    //     data: null,
    //     id: '11ecedae7a9b8e30c8fc5ca2897d76f9',
    //     type: 'receipt_pending',
    // },
    // 4: {
    //     created: '2022-06-16T23:56:57.000Z',
    //     data: null,
    //     id: '11ecedae7a9b8e30c8fc5ca2897d76f0',
    //     type: 'receipt_pending',
    // },
    // 5: { // new user
    //     created: '2022-06-16T23:56:57.000Z',
    //     data: {
    //         points: 25,
    //         events: ['select_avatar', 'submit_birthdate'],
    //     },
    //     id: '11ecedae7a9b8e30c8fc5ca2897d76f1',
    //     type: 'points',
    // },
    // 6: { // receipt uploaded and accepted
    //     created: '2022-06-16T23:56:57.000Z',
    //     data: {
    //         points: 100,
    //         events: ['purchase'],
    //     },
    //     id: '11ecedae7a9b8e30c8fc5ca2897d76f2',
    //     type: 'points',
    // },
    // 7: { // receipt uploaded and accepted
    //     created: '2022-06-16T23:56:57.000Z',
    //     data: {
    //         points: 100,
    //         events: ['purchase'],
    //     },
    //     id: '11ecedae7a9b8e30c8fc5ca2897d76f3',
    //     type: 'points',
    // },
};

const getters = {
    pointsNotifications: (state) => Object.values(state).filter(({ type, data }) => (type === POINTS ? !(data?.events?.includes(PURCHASE) && !checkFeatureFlag('fileUploadNotifications')) : false)),
    receiptNotifications: (state) => {
        const filteredNotifications = Object.values(state)
        .filter(({ type }) => type === RECEIPT_APPROVED || type === RECEIPT_PENDING)
        .map(({ type }) => type);
        return [...new Set(filteredNotifications)];
    },
    notificationsByType: (state) => (queryType) => Object.values(state).filter(({ type }) =>
        queryType === type),
};

const mutations = {
    updateNotificationsData (state, payload) {
        // payload is an array of objects
        for (const notification of payload) {
            const { data = {}, id, type } = notification;

            if (data?.feature) {
                const { feature = '', target = 'none' } = data || {};

                if (!checkFeatureFlag(feature)) continue;

                if (type === 'new_content_indicator') {
                    if (!Object.keys(state.new_content_indicators).includes(target)) {
                        console.error(`invalid new_content_indicators target: ${target}`);
                        continue;
                    }

                    if (!Array.isArray(state.new_content_indicators[target])) state.new_content_indicators[target] = [];

                    state.new_content_indicators[target].push(id);
                }
                else {
                    state.modals[id] = feature;
                }

                continue;
            }

            if (type === 'receipt_approved') state.modals[id] = type;
            else state[id] = notification;
        }
    },

    deleteById (state, id) {
        const objToDelete = Object.entries(state).find(([_, value]) => value.id === id);
        if (objToDelete) delete state[objToDelete[0]];
    },

    removeNewContentIndicator (state, tab) {
        state.new_content_indicators[tab] = [];
    },

    removeModal (state, id) {
        delete state.modals[id];
    },

    resetModals (state) {
        state.modals = {};
    },
};

const actions = {
    async getNotifications ({ commit }) {
        const { data: { result: { notifications } } } =
            await axios.get(`${api.base}/notifications`);

        commit('resetModals');
        commit('updateNotificationsData', notifications);
    },

    async pollNotifications ({ commit }) {
        try {
            const { data: { result } } = await axios.get(`${api.base}/notifications/poll`);
            commit('updateNotificationsData', result.notifications);
        }
        catch (err) {
            console.error(err);
            throw err;
        }
    },

    async markModalAsRead ({ commit, dispatch }, modalID) {
        // immediately reset state
        commit('removeModal', modalID);
        await dispatch('markNotificationAsRead', modalID);
    },

    markNewContentIndicatorAsRead ({ commit, dispatch, rootState, state }) {
        const idsToMark = state.new_content_indicators[rootState.drawer.currentTab];

        if (idsToMark?.length) {
            idsToMark.forEach(id => dispatch('markNotificationAsRead', id));
            commit('removeNewContentIndicator', rootState.drawer.currentTab);
        }
    },

    async markNotificationAsRead (_, notification_id) {
        try {
            await axios.post(`${api.base}/notifications/mark`, { notification_id });
        }
        catch (err) {
            console.error(err);
        }
    },

    removeNotificationById ({ commit }, id) {
        commit('deleteById', id);
    },

    removeNotificationsByType ({ commit }, notifications) {
        notifications.forEach(n => commit('deleteById', n.id));
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
