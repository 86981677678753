import { DefaultLayout } from '../layouts';

// Asynchronous component definitions for lazy-loading routes.
// https://router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments
const
    LifecyclePage = async () => import(
        /* webpackChunkName: 'lifecycle' */
        '../views/LifecyclePage.vue'
    );

export default [
    { path: '/lifecycle', alias: '/', component: LifecyclePage, meta: {
        public: true,
        activeInPhase: ['pre-launch', 'expired'],
        layout: DefaultLayout,
    } },
];
