
    import { mapState, mapGetters, mapActions } from 'vuex';
    import { openPopup } from '../popup';
    import openModal, { HowItWorksModal } from '../modals';
    import { checkFeatureFlag } from '../../js/feature-flags';
    import SocialShare from './SocialShare.vue';

    export default {
        components: {
            SocialShare,
        },

        props: {
            hidefooterlinks: {
                type: String,
                default: '',
            },
        },

        computed: {
            ...mapState(['app']),
            ...mapGetters({
                loggedIn: 'profile/loggedIn',
            }),
        },

        methods: {
            ...mapActions({
                logOut: 'profile/logOut',
            }),

            openPopup,
            openHowItWorksModal () {
                this.$parent.$data.menuOpen = false;
                openModal(HowItWorksModal);
            },

            doLogout () {
                this.logOut();
                this.$router.replace('/');
            },

            checkFeatureFlag,
        },

        i18nOptions: { namespaces: ['links'] },
    };
