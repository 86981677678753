
    import { mapGetters } from 'vuex';
    import MenuBar from '@components/MenuBar.vue';
    import PromoLinks from '@components/PromoLinks.vue';

    export default {
        components: {
            MenuBar,
            PromoLinks,
        },

        computed: {
            ...mapGetters({
                loggedIn: 'profile/loggedIn',
            }),
        },

        i18nOptions: { namespaces: ['global'] },
    };
