
    export default {
        data () {
            return {
                menuOpen: false,
            };
        },

        computed: {
            iconTitle () {
                const state = this.menuOpen ? 'Close' : 'Open';
                return `${state} Menu`;
            },
        },

        methods: {
            toggleMenu () {
                this.menuOpen = !this.menuOpen;
            },
        },
    };
