import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "promo_links" }
const _hoisted_2 = ["title"]
const _hoisted_3 = ["title"]
const _hoisted_4 = ["href", "title"]
const _hoisted_5 = ["title"]
const _hoisted_6 = ["href", "title"]
const _hoisted_7 = ["title"]
const _hoisted_8 = ["title"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SocialShare = _resolveComponent("SocialShare")

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("a", {
      href: "#",
      title: _ctx.$t('howitworks'),
      onClick: _cache[0] || (_cache[0] = _withModifiers((...args) => ($options.openHowItWorksModal && $options.openHowItWorksModal(...args)), ["prevent"]))
    }, _toDisplayString(_ctx.$t('howitworks')), 9, _hoisted_2),
    _createElementVNode("a", {
      href: "#/terms",
      title: `${_ctx.$t('termsandcondition_copy')} ${_ctx.$t('links:new_window')}`
    }, _toDisplayString(_ctx.$t('termsandcondition_copy')), 9, _hoisted_3),
    _createElementVNode("a", {
      href: _ctx.$t('privacy_url'),
      title: `${_ctx.$t('privacy_copy')} ${_ctx.$t('links:new_window')}`,
      target: "_blank"
    }, _toDisplayString(_ctx.$t('privacy_copy')), 9, _hoisted_4),
    _createElementVNode("a", {
      href: "#/faq",
      title: `${_ctx.$t('faq_copy')} ${_ctx.$t('links:new_window')}`,
      onClick: _cache[1] || (_cache[1] = _withModifiers((...args) => ($options.openPopup && $options.openPopup(...args)), ["prevent"]))
    }, _toDisplayString(_ctx.$t('faq_copy')), 9, _hoisted_5),
    _createElementVNode("a", {
      href: _ctx.$t('terms_url'),
      class: "last_nav_item",
      title: `${_ctx.$t('terms_copy')} ${_ctx.$t('links:new_window')}`,
      target: "_blank"
    }, _toDisplayString(_ctx.$t('terms_copy')), 9, _hoisted_6),
    (_ctx.loggedIn && $props.hidefooterlinks != 'footer')
      ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          href: "#/profile",
          title: _ctx.$t('profile')
        }, _toDisplayString(_ctx.$t('profile')), 9, _hoisted_7))
      : _createCommentVNode("", true),
    (_ctx.loggedIn && $props.hidefooterlinks != 'footer')
      ? (_openBlock(), _createElementBlock("a", {
          key: 1,
          href: "#/transaction_history",
          title: _ctx.$t('transaction_history')
        }, _toDisplayString(_ctx.$t('transaction_history')), 9, _hoisted_8))
      : _createCommentVNode("", true),
    (_ctx.loggedIn && $props.hidefooterlinks != 'footer')
      ? (_openBlock(), _createElementBlock("a", {
          key: 2,
          href: "",
          onClick: _cache[2] || (_cache[2] = _withModifiers($event => ($options.doLogout()), ["prevent"]))
        }, _toDisplayString(_ctx.$t('logout')), 1))
      : _createCommentVNode("", true),
    (_ctx.loggedIn && $props.hidefooterlinks != 'footer')
      ? (_openBlock(), _createBlock(_component_SocialShare, {
          key: 3,
          "show-disclaimer": true
        }))
      : _createCommentVNode("", true)
  ]))
}