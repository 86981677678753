
    import { mapActions } from 'vuex';
    import GiftCardRewardLockup from '@components/GiftCardRewardLockup.vue';
    import openModal, { RewardRedemptionCompleteModal, ErrorModal } from '../modals';
    import BaseModal from './BaseModal';

    export default {
        name: 'RewardRedemptionConfirmationModal',

        components: {
            GiftCardRewardLockup,
        },

        extends: BaseModal,

        props: {
            reward: {
                required: true,
                type: Object,
            },
        },

        methods: {
            ...mapActions({
                getDrawerData: 'drawer/getDrawerData',
                redeemReward: 'profile/redeemReward',
            }),

            async redeem () {
                try {
                    const response = await this.redeemReward(this.reward.id);
                    this.getDrawerData();
                    openModal(RewardRedemptionCompleteModal, { reward: response });
                }
                catch (err) {
                    openModal(ErrorModal);
                }

                this.closeModal();
            },
        },

        i18nOptions: { namespaces: ['rewards_catalog'] },
    };
