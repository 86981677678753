import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  id: "jms-opt-in-modal",
  class: "modal default",
  role: "dialog",
  "aria-labelledby": "modal-title"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { id: "modal-title" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { id: "modal-title" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BaseCheckbox = _resolveComponent("BaseCheckbox")

  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay",
    onClick: _cache[4] || (_cache[4] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (!$data.confirmed)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t('headline_copy')), 1),
            _createElementVNode("form", {
              id: "jms-optin-form",
              name: "jms-optin-form",
              onSubmit: _cache[1] || (_cache[1] = _withModifiers((...args) => ($options.submit && $options.submit(...args)), ["prevent"]))
            }, [
              _createVNode(_component_BaseCheckbox, {
                modelValue: $data.optin,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.optin) = $event)),
                name: "jms-optin",
                label: _ctx.$t('fields.optin', { 'privacyUrl': _ctx.$t('links:jms_privacy_url'), 'termsUrl': _ctx.$t('links:jms_terms_url') })
              }, null, 8, ["modelValue", "label"]),
              _createElementVNode("button", {
                type: "submit",
                disabled: !$data.optin
              }, _toDisplayString(_ctx.$t('sign_up')), 9, _hoisted_4)
            ], 32)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.$t('confirmation_headline_copy')), 1),
            _createElementVNode("button", {
              onClick: _cache[2] || (_cache[2] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
            }, _toDisplayString(_ctx.$t('back_dashboard')), 1)
          ])),
      _createElementVNode("a", {
        href: "#",
        class: "close-btn",
        title: "Close modal",
        onClick: _cache[3] || (_cache[3] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, _cache[5] || (_cache[5] = [
        _createElementVNode("i", {
          "aria-hidden": "true",
          class: "fa-solid fa-xmark"
        }, null, -1)
      ]))
    ])
  ]))
}