
import store from '../store/index';
import { fetchActiveFeatureFlags } from '../feature-flags';

export default async function () {
    await store.dispatch('app/loadAppState');
    fetchActiveFeatureFlags();

    document.documentElement.setAttribute('lang', store.state.app.userLanguage);
}
