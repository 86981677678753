import { useModel as _useModel, mergeModels as _mergeModels } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["id", "value", "name"]
const _hoisted_2 = ["for"]


export default {
  __name: 'BaseRadioGroup',
  props: /*@__PURE__*/_mergeModels({
        isError: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        options: {
            type: Array,
            required: true,
        },
    }, {
    "modelValue": { type: String, required: true },
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

    

    const model = _useModel(__props, "modelValue");

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["base-radio-group", { error: __props.isError }])
  }, [
    _createElementVNode("p", null, _toDisplayString(__props.label), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.options, (option) => {
      return (_openBlock(), _createElementBlock("span", {
        key: `radio_${option.value}`,
        class: "base-radio field"
      }, [
        _withDirectives(_createElementVNode("input", {
          id: option.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((model).value = $event)),
          type: "radio",
          value: option.value,
          name: __props.name
        }, null, 8, _hoisted_1), [
          [_vModelRadio, model.value]
        ]),
        _createElementVNode("label", {
          for: option.value
        }, _toDisplayString(option.label), 9, _hoisted_2)
      ]))
    }), 128))
  ], 2))
}
}

}