import { ProfileLayout } from '../layouts';
// import { checkFeatureFlag } from '../feature-flags';

// Asynchronous component definitions for lazy-loading routes.
// https://router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments
const OptoutPage = async () => import(
    /* webpackChunkName: 'optout' */
    '../views/OptoutPage.vue'
);

const optoutRoutes = [
    {
        path: '/optout',
        alias: '/',
        component: OptoutPage,
        meta: {
            public: true,
            activeInPhase: ':any',
            layout: ProfileLayout,
        },
    },
];

// THIS DOES NOT WORK
// TODO: Work with Straub when he returns to understand why this feature flag doesn't return true if the global flag is on but the local flag is off
// console.log('optout test', checkFeatureFlag('manageCommunications'));
// if (checkFeatureFlag('manageCommunications')) {
//     optoutRoutes.push({
//         path: '/optout',
//         alias: '/',
//         component: OptoutPage,
//         meta: {
//             public: true,
//             activeInPhase: ':any',
//             layout: ProfileLayout,
//         },
//     });
// }

export default optoutRoutes;
