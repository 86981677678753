import { useModel as _useModel, mergeModels as _mergeModels } from 'vue'
import { vModelCheckbox as _vModelCheckbox, mergeProps as _mergeProps, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id", "name"]
const _hoisted_2 = ["for", "innerHTML"]


export default /*@__PURE__*/Object.assign({ inheritAttrs: false }, {
  __name: 'BaseCheckbox',
  props: /*@__PURE__*/_mergeModels({
        isError: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
    }, {
    "modelValue": { type: Boolean, required: true },
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

    
    

    const model = _useModel(__props, "modelValue");

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["base-checkbox field", { error: __props.isError }])
  }, [
    _withDirectives(_createElementVNode("input", _mergeProps(_ctx.$attrs, {
      id: __props.name,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((model).value = $event)),
      type: "checkbox",
      name: __props.name
    }), null, 16, _hoisted_1), [
      [_vModelCheckbox, model.value]
    ]),
    _createElementVNode("label", {
      for: __props.name,
      innerHTML: __props.label
    }, null, 8, _hoisted_2)
  ], 2))
}
}

})