
    import { mapActions } from 'vuex';
    import { BaseCheckbox } from '@components/form';
    import BaseModal from './BaseModal';

    export default {
        name: 'JmsOptInModal',

        components: {
            BaseCheckbox,
        },

        extends: BaseModal,

        data () {
            return {
                modalName: 'jms_optin',
                optin: false,
                confirmed: false,
            };
        },

        methods: {
            ...mapActions({
                'jmsOptIn': 'profile/jmsOptIn',
            }),

            async submit () {
                if (this.optin) await this.jmsOptIn();
                this.confirmed = true;
            },
        },

        i18nOptions: { namespaces: ['jms_opt_in', 'buttons', 'links'] },
    };
