
    import BaseModal from './BaseModal';
    import openModal, { CouponDisclaimerModal } from '.';

    export default {
        name: 'CouponModal',

        extends: BaseModal,

        props: {
            couponId: {
                type: Number,
                required: true,
            },
            couponKey: { type: String, required: true },
        },

        data () {
            return {
                modalName: 'coupon',
            };
        },

        methods: {
            openTermsModal () {
                openModal(CouponDisclaimerModal, { couponId: this.couponId, couponKey: this.couponKey });
                this.closeModal();
            },
        },

        i18nOptions: { namespaces: ['coupons'] },
    };
