import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withModifiers as _withModifiers } from "vue"
import _imports_0 from '@public/img/promotional_modal/coffee_confetti.png'


const _hoisted_1 = {
  id: "dahe-sweepstakes-confirmation-modal",
  class: "modal sweepstakes-confirmation-modal",
  role: "dialog",
  "aria-labelledby": "modal-title"
}
const _hoisted_2 = {
  key: 0,
  class: "header-img",
  src: _imports_0,
  alt: ""
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { key: 1 }
const _hoisted_6 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_t = _resolveDirective("t")

  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay",
    onClick: _cache[4] || (_cache[4] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (!$options.checkFeatureFlag('fallPromo2024'))
        ? (_openBlock(), _createElementBlock("img", _hoisted_2))
        : _createCommentVNode("", true),
      _createElementVNode("h2", {
        id: "modal-title",
        class: _normalizeClass($options.checkFeatureFlag('fallPromo2024') ? 'hh-sweeps-confirmation-header' : ''),
        innerHTML: _ctx.$t($props.apiKey + '.sweepstake_confirmation.headline_copy')
      }, null, 10, _hoisted_3),
      _createElementVNode("p", {
        innerHTML: _ctx.$t($props.apiKey + '.sweepstake_confirmation.body_copy_' + $props.sweepstakeConfirmationCopy)
      }, null, 8, _hoisted_4),
      (!$options.checkFeatureFlag('fallPromo2024'))
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("p", {
              class: "disclaimer",
              innerHTML: _ctx.$t($props.apiKey + '.sweepstake_confirmation.disclaimer', { tb2URL: _ctx.app.tb2URL, fall24tb2URL: _ctx.app.fall24tb2Url })
            }, null, 8, _hoisted_6)
          ]))
        : _createCommentVNode("", true),
      (!$options.checkFeatureFlag('fallPromo2024') && !_ctx.profile.receipt_limited)
        ? _withDirectives((_openBlock(), _createElementBlock("button", {
            key: 2,
            onClick: _cache[0] || (_cache[0] = (...args) => ($options.exitToFileUpload && $options.exitToFileUpload(...args)))
          }, null, 512)), [
            [_directive_t, 'buttons:upload']
          ])
        : _createCommentVNode("", true),
      (!$options.checkFeatureFlag('fallPromo2024'))
        ? _withDirectives((_openBlock(), _createElementBlock("button", {
            key: 3,
            class: "button-pink",
            onClick: _cache[1] || (_cache[1] = (...args) => ($options.exitToOpenRedeem && $options.exitToOpenRedeem(...args)))
          }, null, 512)), [
            [_directive_t, 'buttons:redeem_points']
          ])
        : _createCommentVNode("", true),
      ($options.checkFeatureFlag('fallPromo2024'))
        ? _withDirectives((_openBlock(), _createElementBlock("button", {
            key: 4,
            onClick: _cache[2] || (_cache[2] = (...args) => ($options.exitToPlay && $options.exitToPlay(...args)))
          }, null, 512)), [
            [_directive_t, 'buttons:play_now']
          ])
        : _createCommentVNode("", true),
      _createElementVNode("a", {
        href: "#",
        class: "close-btn",
        title: "Close modal",
        onClick: _cache[3] || (_cache[3] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, _cache[5] || (_cache[5] = [
        _createElementVNode("i", {
          "aria-hidden": "true",
          class: "fa-solid fa-xmark"
        }, null, -1)
      ]))
    ])
  ]))
}