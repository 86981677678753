
    import { mapActions, mapState } from 'vuex';
    import { modalLinkHandler } from '@public/js/utils.js';
    import { BaseCheckbox } from '@components/form';
    import BaseModal from './BaseModal';
    import openModal, { PhotoUploadTipsModal } from '.';

    export default {
        name: 'SeasonalModal',

        components: {
            BaseCheckbox,
        },

        extends: BaseModal,

        props: {
            apiKey: {
                type: String,
                required: true,
            },
            modalID: {
                default: null,
                type: String,
            },
            seasonID: {
                required: true,
                type: String,
            },
        },

        data () {
            return {
                modalName: 'seasonal',
                optin: false,
            };
        },

        computed: {
            ...mapState(['app']),
            getImage () {
                return (suffix, ext = 'png') => require(`../../img/seasonal_modal/${this.seasonID}-${suffix}.${ext}`);
            },
        },

        methods: {
            ...mapActions({
                'markModalAsRead': 'notifications/markModalAsRead',
                'overlayOptIn': 'overlay/overlayOptIn',
            }),

            async enterNow () {
                if (this.optin) await this.overlayOptIn({ apiKey: this.apiKey, rules: this.optin });
                this.closeModal(this.optin);
            },

            clickButton () {
                if (this.modalID) {
                    this.markModalAsRead(this.modalID);
                }
                this.$emit('close-modal', { action: 'buttonClick', tab: this.$t(`${this.seasonID}.tab`) });
                this.$webAnalytics.track('click', { category: 'Seasonal Modal', label: 'button', value: this.seasonID });
                if (this.seasonID == 'punchcardPromo') {
                    openModal(PhotoUploadTipsModal, { isUploadOnboarding: true });
                }
            },

            openPhotoUpload () {
                if (this.modalID) {
                    this.markModalAsRead(this.modalID);
                }
                this.$emit('close-modal', { action: 'openPhotoUpload' });
                this.$webAnalytics.track('click', { category: 'Seasonal Modal', label: 'button', value: this.seasonID });
            },

            modalLinkHandler,
        },

        i18nOptions: { namespaces: ['seasonal_modal', 'buttons'] },
    };
