import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@public/img/icon_dunkin.svg'


const _hoisted_1 = ["title"]
const _hoisted_2 = ["inert"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["menu", { 'active': $data.menuOpen }])
  }, [
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "overlay" }, null, -1)),
    _createElementVNode("button", {
      class: "menu-buttons",
      title: $options.iconTitle,
      onClick: _cache[0] || (_cache[0] = _withModifiers((...args) => ($options.toggleMenu && $options.toggleMenu(...args)), ["prevent"]))
    }, _cache[1] || (_cache[1] = [
      _createElementVNode("span", { class: "navigation-icon" }, null, -1)
    ]), 8, _hoisted_1),
    _createElementVNode("div", {
      inert: !$data.menuOpen,
      class: "menu-panel"
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 8, _hoisted_2),
    _cache[3] || (_cache[3] = _createElementVNode("div", { id: "icon_dunkin" }, [
      _createElementVNode("img", {
        class: "icon_dunkin",
        src: _imports_0,
        alt: "icon_dunkin"
      })
    ], -1))
  ], 2))
}