import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["disabled"]
const _hoisted_5 = {
  key: 4,
  class: "maybe-later"
}
const _hoisted_6 = {
  key: 5,
  class: "maybe-later"
}
const _hoisted_7 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BaseCheckbox = _resolveComponent("BaseCheckbox")

  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay",
    onClick: _cache[8] || (_cache[8] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["modal seasonal_modal default", `${$props.seasonID}_modal`]),
      role: "dialog",
      "aria-labelledby": "modal-title"
    }, [
      _createElementVNode("h2", {
        id: "modal-title",
        innerHTML: _ctx.$t(`${$props.seasonID}.headline_copy`)
      }, null, 8, _hoisted_1),
      _createElementVNode("h3", {
        innerHTML: _ctx.$t(`${$props.seasonID}.subheadline_copy`)
      }, null, 8, _hoisted_2),
      _createElementVNode("p", {
        onClick: _cache[0] || (_cache[0] = (...args) => ($options.modalLinkHandler && $options.modalLinkHandler(...args))),
        innerHTML: _ctx.$t(`${$props.seasonID}.body_copy`)
      }, null, 8, _hoisted_3),
      ($props.seasonID === 'fallPromo2024')
        ? (_openBlock(), _createBlock(_component_BaseCheckbox, {
            key: 0,
            modelValue: $data.optin,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($data.optin) = $event)),
            name: "overlay-optin",
            label: _ctx.$t(`${$props.seasonID}.optin`, { fall24tb2URL: _ctx.app.fall24tb2Url })
          }, null, 8, ["modelValue", "label"]))
        : _createCommentVNode("", true),
      ($props.seasonID === 'punchcardPromo')
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            onClick: _cache[2] || (_cache[2] = (...args) => ($options.openPhotoUpload && $options.openPhotoUpload(...args)))
          }, _toDisplayString(_ctx.$t(`${$props.seasonID}.button_label`)), 1))
        : ($props.seasonID === 'fallPromo2024')
          ? (_openBlock(), _createElementBlock("button", {
              key: 2,
              disabled: !$data.optin,
              onClick: _cache[3] || (_cache[3] = $event => ($options.enterNow()))
            }, _toDisplayString(_ctx.$t(`${$props.seasonID}.button_label`)), 9, _hoisted_4))
          : (_openBlock(), _createElementBlock("button", {
              key: 3,
              onClick: _cache[4] || (_cache[4] = $event => ($options.clickButton()))
            }, _toDisplayString(_ctx.$t(`${$props.seasonID}.button_label`)), 1)),
      ($props.seasonID === 'fallPromo2024')
        ? (_openBlock(), _createElementBlock("p", _hoisted_5, [
            _createElementVNode("a", {
              href: "",
              onClick: _cache[5] || (_cache[5] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
            }, _toDisplayString(_ctx.$t(`${$props.seasonID}.no_thanks`)), 1)
          ]))
        : (_openBlock(), _createElementBlock("p", _hoisted_6, [
            _createElementVNode("a", {
              href: "",
              onClick: _cache[6] || (_cache[6] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
            }, _toDisplayString(_ctx.$t('maybe_later')), 1)
          ])),
      (_ctx.$t(`${$props.seasonID}.disclaimer`))
        ? (_openBlock(), _createElementBlock("p", {
            key: 6,
            class: "disclaimer",
            innerHTML: _ctx.$t(`${$props.seasonID}.disclaimer`, { tb2URL: _ctx.app.tb2URL, fall24tb2URL: _ctx.app.fall24tb2Url })
          }, null, 8, _hoisted_7))
        : _createCommentVNode("", true),
      _createElementVNode("a", {
        href: "#",
        class: "close-btn",
        title: "Close modal",
        onClick: _cache[7] || (_cache[7] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, _cache[9] || (_cache[9] = [
        _createElementVNode("i", {
          "aria-hidden": "true",
          class: "fa-solid fa-xmark"
        }, null, -1)
      ]))
    ], 2)
  ]))
}