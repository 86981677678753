import axios from 'axios';
import { reactive } from 'vue';
import api from '../../api';

const getDefaultState = () => reactive({
    totalPoints: 0,
    totalSweeps: 0,
});

const state = getDefaultState();

const mutations = {
    updateLoyaltyState (state, data) {
        if (!Array.isArray(data)) {
            data = [data];
        }

        for (const values of data) {
            for (const key in values) {
                state[key] = values[key];
            }
        }
    },
};

const actions = {
    async getPromotionRewardsEarned ({ dispatch, commit }, { apiKey }) {

        return dispatch('makeCall', {
            type: 'get',
            endpoint: `loyalty/${apiKey}/rewards`,
        });
    },

    async makeCall ({ state, dispatch, commit }, {
        type = 'post',
        endpoint,
        data = {},
    }) {

        try {
            const response = await axios[type](`${api.base}/${endpoint}`, data);
            commit('updateLoyaltyState', response.data);

            return response;
        }
        catch (err) {
            console.error(`error calling ${endpoint}`, err);

            throw err;
        }
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
