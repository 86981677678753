
    import axios from 'axios';
    import { mapActions, mapState } from 'vuex';

    import '../../vendor/esocial';

    import api from '../api';
    import { checkFeatureFlag } from '../feature-flags';

    export default {
        name: 'SocialShare',

        props: {
            showDisclaimer: {
                type: Boolean,
                default: false,
            },
        },

        data () {
            return {
                referralCodes: {
                    email: '',
                    facebook: '',
                    twitter: '',
                },
            };
        },

        computed: {
            ...mapState(['app']),
            mailTo () {
                const subject = this.$t('email.subject');
                const referralURL = encodeURI(`${this.app.siteURL}?rcode`) + encodeURIComponent(`=${this.referralCodes.email}`);
                const body1 = encodeURIComponent(this.$t('email.body_copy.1'));
                const body2 = encodeURIComponent(this.$t('email.body_copy.2'));
                const body = `${body1}%0A${referralURL}%0A%0A${body2}`;
                return `mailto:?subject=${subject}&body=${body}`;
            },
        },

        created () {
            this.getReferralCodes();
        },

        methods: {
            ...mapActions({
                'recordEvent': 'profile/recordEvent',
                'pollNotifications': 'notifications/pollNotifications',
            }),

            checkFeatureFlag,

            getReferralCodes () {
                const channels = ['email', 'facebook', 'twitter'];

                for (const channel of channels) {
                    // Using traditional Promise chain syntax instead of await
                    // since we can let them all run simultaneously
                    axios.post(`${api.base}/profiles/referrals`, { channel })
                        .then((response) => {
                            this.referralCodes[response.data.channel] = response.data.referral_code;
                            return response;
                        })
                        .catch((error) => {
                            console.error('error getting referral code', error);
                        });
                }
            },

            async twitterSelection () {
                try {
                    await this.recordEvent('tweet');
                    if (this.checkFeatureFlag('notifications')) this.pollNotifications();
                }
                catch (err) {
                    console.error(err);
                }
            },
        },

        i18nOptions: { namespaces: ['share'] },
    };
