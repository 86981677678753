
import axios from 'axios';
import { reactive } from 'vue';

import config from 'rocketship-config';
import api from '../../api';

const OAUTH_ENABLED = true;

const stateTokenKey = 'state-token';
const authTokenKey = 'dahe-auth-token';

const getDefaultState = () => reactive({
    stateToken: getSessionStorageItem(stateTokenKey),
    authToken: getSessionStorageItem(authTokenKey),
    enabled: OAUTH_ENABLED,
});

const state = getDefaultState();

const getters = {
    inOauthFlow: (state) => OAUTH_ENABLED && !!state.stateToken,
};

const mutations = {
    resetOauthState (state) {
        for (const key in state) {
            delete state[key];
        }

        const defaultState = getDefaultState();
        for (const key in defaultState) {
            state[key] = defaultState[key];
        }
    },

    updateOauthState (state, data) {
        if (!Array.isArray(data)) {
            data = [data];
        }

        for (const values of data) {
            for (const key in values) {
                state[key] = values[key];

                if (key === 'accessToken') {
                    const { accessToken } = state;

                    if (accessToken) setSessionStorageItem(authTokenKey, accessToken);
                }
            }
        }
    },
};

const actions = {
    async authorize ({ dispatch, state }, { data }) {

        data.state = data.state || state.stateToken;

        const { data: { redirectURL } } = await dispatch('makeCall', {
            endpoint: 'oauth/authorize',
            data,
        });

        removeSessionStorageItem(stateTokenKey);

        return redirectURL;
    },

    async requestAuthorizationCode ({ dispatch, commit, state }) {
        if (!state.enabled) return;

        const { stateToken } = state;

        const { data: { redirectURL } } = await dispatch('makeCall', {
            endpoint: 'oauth/code',
            data: { state: stateToken },
        });

        removeSessionStorageItem(stateTokenKey);

        return redirectURL;
    },

    async resetSession ({ dispatch, commit }) {
        removeSessionStorageItem(stateTokenKey);
        removeSessionStorageItem(authTokenKey);

        commit('resetOauthState');
    },

    async makeCall ({ state, dispatch, commit }, {
        type = 'post',
        endpoint,
        data = {},
    }) {
        commit('resetOauthState');

        try {
            commit('updateOauthState', data);
            const response = await axios[type](`${api.base}/${endpoint}`, data);
            commit('updateOauthState', response.data);
            return response;
        }
        catch (err) {
            dispatch('resetSession');

            console.error(
                `error making ${endpoint} call`,
                err.message,
                err,
            );

            throw err;
        }
    },
};

function setSessionStorageItem (key, item) {
    if (!OAUTH_ENABLED || !key) return;

    try { window.sessionStorage.setItem(key, item); }
    catch (err) { console.error(`sessionStorage error setting ${key}`, err); }
}

function getSessionStorageItem (key) {
    if (!OAUTH_ENABLED || !key) return;

    try { return window.sessionStorage.getItem(key); }
    catch (err) { console.error(`sessionStorage error getting ${key}`, err); }
}

function removeSessionStorageItem (key) {
    try { window.sessionStorage.removeItem(key); }
    catch (err) { console.error(`sessionStorage error removing ${key}`, err); }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
