
    import BaseModal from './BaseModal';

    export default {
        name: 'CouponDisclaimerModal',

        extends: BaseModal,

        props: {
            couponKey: {
                type: String,
                required: true,
            },
        },

        i18nOptions: { namespaces: ['coupons'] },
    };
