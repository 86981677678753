import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  id: "birthdate-modal",
  class: "modal orange-bkgd",
  role: "dialog",
  "aria-labelledby": "modal-title"
}
const _hoisted_2 = { class: "points-banner" }
const _hoisted_3 = { id: "modal-title" }
const _hoisted_4 = { type: "submit" }
const _hoisted_5 = {
  key: 0,
  class: "skip"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BaseSelect = _resolveComponent("BaseSelect")
  const _component_Validator = _resolveComponent("Validator")

  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay",
    onClick: _cache[5] || (_cache[5] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('birthdate.points')), 1),
      _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t('birthdate.headline_copy')), 1),
      _createVNode(_component_Validator, {
        ref: "validator",
        guards: $data.validatorGuards,
        data: $data.birthdate
      }, {
        default: _withCtx(({ isError }) => [
          _createElementVNode("form", {
            onSubmit: _cache[2] || (_cache[2] = _withModifiers($event => ($options.submitBirthdate()), ["prevent"]))
          }, [
            _createVNode(_component_BaseSelect, {
              modelValue: $data.birthdate.month,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.birthdate.month) = $event)),
              name: "month",
              label: _ctx.$t('field.birth_month'),
              "select-one": _ctx.$t('select_one_fields.month_header'),
              options: $options.monthOptions,
              "is-error": isError('month'),
              "error-label": _ctx.$t('birthdate.month_error')
            }, null, 8, ["modelValue", "label", "select-one", "options", "is-error", "error-label"]),
            _createVNode(_component_BaseSelect, {
              modelValue: $data.birthdate.day,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($data.birthdate.day) = $event)),
              name: "day",
              label: _ctx.$t('field.birth_day'),
              "select-one": _ctx.$t('select_one_fields.day_header'),
              options: $options.dayOptions,
              "disable-first-option": false,
              "disable-select": !$data.birthdate.month.length,
              "is-error": isError('day'),
              "error-label": _ctx.$t('birthdate.day_error')
            }, null, 8, ["modelValue", "label", "select-one", "options", "disable-select", "is-error", "error-label"]),
            _createElementVNode("button", _hoisted_4, _toDisplayString(_ctx.$t('buttons:continue')), 1)
          ], 32)
        ]),
        _: 1
      }, 8, ["guards", "data"]),
      ($props.onboarding)
        ? (_openBlock(), _createElementBlock("p", _hoisted_5, [
            _createElementVNode("a", {
              href: "",
              onClick: _cache[3] || (_cache[3] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
            }, _toDisplayString(_ctx.$t('skip')), 1)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("a", {
        href: "#",
        class: "close-btn",
        title: "Close modal",
        onClick: _cache[4] || (_cache[4] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, _cache[6] || (_cache[6] = [
        _createElementVNode("i", {
          "aria-hidden": "true",
          class: "fa-solid fa-xmark"
        }, null, -1)
      ]))
    ])
  ]))
}