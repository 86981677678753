import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@public/img/header.svg'


const _hoisted_1 = {
  class: "modal default exit-modal",
  role: "dialog",
  "aria-labelledby": "modal-title"
}
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_t = _resolveDirective("t")

  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay",
    onClick: _cache[3] || (_cache[3] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _cache[5] || (_cache[5] = _createElementVNode("img", {
        class: "headerimg",
        src: _imports_0,
        alt: "Dunkin' At Home Extras"
      }, null, -1)),
      _createElementVNode("h2", {
        id: "modal-title",
        innerHTML: _ctx.$t($props.apiKey + '.exit.header')
      }, null, 8, _hoisted_2),
      _createElementVNode("p", {
        innerHTML: _ctx.$t($props.apiKey + '.exit.body_copy')
      }, null, 8, _hoisted_3),
      _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
      _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
      _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1)),
      _withDirectives(_createElementVNode("a", {
        href: `/sso_redirect?target=${$props.apiKey}`,
        class: "btn",
        "data-e2e": "exit-modal-close",
        onClick: _cache[0] || (_cache[0] = _withModifiers($event => ($options.buttonContinue()), ["prevent"]))
      }, null, 8, _hoisted_4), [
        [_directive_t, 'buttons:continue']
      ]),
      _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1)),
      _cache[10] || (_cache[10] = _createElementVNode("br", null, null, -1)),
      _cache[11] || (_cache[11] = _createElementVNode("br", null, null, -1)),
      _withDirectives(_createElementVNode("a", {
        href: "#",
        class: "btn button-white",
        "data-e2e": "exit-modal-close",
        onClick: _cache[1] || (_cache[1] = _withModifiers($event => ($options.buttonClose()), ["prevent"]))
      }, null, 512), [
        [_directive_t, 'buttons:go_back']
      ]),
      _createElementVNode("a", {
        href: "#",
        class: "close-btn",
        title: "Close modal",
        "data-e2e": "exit-modal-close",
        onClick: _cache[2] || (_cache[2] = _withModifiers($event => ($options.buttonClose()), ["prevent"]))
      }, _cache[4] || (_cache[4] = [
        _createElementVNode("i", {
          "aria-hidden": "true",
          class: "fa-solid fa-xmark"
        }, null, -1)
      ]))
    ])
  ]))
}