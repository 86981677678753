import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@public/img/bg_footer_product.png'
import _imports_1 from '@public/img/bg_footer_product_mobile.png'
import _imports_2 from '@public/img/dunkin.svg'


const _hoisted_1 = { class: "footer" }
const _hoisted_2 = {
  key: 0,
  class: "artificial-tests"
}
const _hoisted_3 = ["textContent"]
const _hoisted_4 = ["data"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_PromoLinks = _resolveComponent("PromoLinks")

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("img", {
      class: "productimg large-only",
      src: _imports_0,
      alt: ""
    }, null, -1)),
    _cache[1] || (_cache[1] = _createElementVNode("img", {
      class: "productimg small-only",
      src: _imports_1,
      alt: ""
    }, null, -1)),
    _createVNode(_component_PromoLinks, {
      "aria-label": "footer_links",
      class: "large-only",
      hidefooterlinks: $props.hidelinks
    }, null, 8, ["hidefooterlinks"]),
    _cache[2] || (_cache[2] = _createElementVNode("img", {
      class: "dunkinimg",
      src: _imports_2,
      alt: "dunkin"
    }, null, -1)),
    _cache[3] || (_cache[3] = _createElementVNode("p", { class: "disclaimer recapdisc" }, [
      _createTextVNode(" This site is protected by reCAPTCHA and the Google "),
      _createElementVNode("a", {
        href: "https://policies.google.com/privacy",
        target: "_blank"
      }, "Privacy Policy"),
      _createTextVNode(" and "),
      _createElementVNode("a", {
        href: "https://policies.google.com/terms",
        target: "_blank"
      }, "Terms of Service"),
      _createTextVNode(" apply. ")
    ], -1)),
    (_ctx.app.isAutomatedTest)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, [
          _createElementVNode("span", {
            class: "test-copy",
            textContent: _toDisplayString(_ctx.$t('testNamespace:testKey'))
          }, null, 8, _hoisted_3),
          _createElementVNode("object", {
            class: "language-flag",
            "aria-label": "Language flag",
            type: "image/svg+xml",
            data: _ctx.$i18nAsset('img/flag.svg'),
            style: {"width":"2em","padding":"0 0.5em","vertical-align":"middle"}
          }, " Flag ", 8, _hoisted_4)
        ]))
      : _createCommentVNode("", true)
  ]))
}