import { useModel as _useModel, mergeModels as _mergeModels } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives, vModelDynamic as _vModelDynamic, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = { class: "error error-message ada-error-message" }
const _hoisted_3 = ["id", "name", "type"]
const _hoisted_4 = { class: "error error-message ada-error-message" }


export default /*@__PURE__*/Object.assign({ inheritAttrs: false }, {
  __name: 'BaseInput',
  props: /*@__PURE__*/_mergeModels({
        isError: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        inputType: {
            type: String,
            required: false,
            default: 'text',
        },
    }, {
    "modelValue": { type: String, required: true },
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

    
    

    const model = _useModel(__props, "modelValue");

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["base-input field", { error: __props.isError }])
  }, [
    _createElementVNode("label", {
      for: __props.name,
      class: _normalizeClass({ 'hide-label': !model.value?.length })
    }, _toDisplayString(__props.label), 11, _hoisted_1),
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "adaErrorTop")
    ], 512), [
      [_vShow, __props.isError && _ctx.$slots.adaErrorTop]
    ]),
    _withDirectives(_createElementVNode("input", _mergeProps({
      id: __props.name,
      ref: `input_${_ctx.$.uid}`
    }, _ctx.$attrs, {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((model).value = $event)),
      name: __props.name,
      type: __props.inputType
    }), null, 16, _hoisted_3), [
      [_vModelDynamic, model.value]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "adaErrorBottom")
    ], 512), [
      [_vShow, __props.isError && _ctx.$slots.adaErrorBottom]
    ])
  ], 2))
}
}

})