
    import { mapActions } from 'vuex';
    import { modalLinkHandler } from '@public/js/utils.js';
    import { BaseCheckbox } from '@components/form';
    import { checkFeatureFlag } from '../../feature-flags';
    import BaseModal from '../BaseModal';

    // TODO: GREEN IDs if we find a way to make these modals dynamic,
    // the public drawer config has a new field for the active promos
    // the field is "apiKey". (currently only on beta)
    // The field needs to be included as a path parameter in the rules acceptance request
    // https://github.com/rocketship-delivery/dunkin_athomeextras/blob/beta/app/config/drawer.json#L77,82
    // /api/profiles/promotion/{apiKey}/rules. This is in an effort to remove all promo specific functionality from server side logic.

    // TODO: fallfestival only has one sweeps prizepool, it is for a 1k cash giveaway
    // for the purpose of keeping the APIs overlay agnostic, the API always expects
    // the front-end to pass it a value. Please set up a default value on the front-end to pass
    // the server if the user is not choosing. currently Fallfestival is passing 1k_cash_giveaway
    // this can potentially be set up in the drawer configs for DAHE
    export default {
        name: 'InitialPromoModal',

        components: {
            BaseCheckbox,
        },

        extends: BaseModal,

        props: {
            apiKey: {
                type: String,
                required: true,
            },
        },

        data () {
            return {
                optin: false,
            };
        },

        methods: {
            ...mapActions({
                'overlayOptIn': 'overlay/overlayOptIn',
            }),

            async enterNow () {
                if (this.optin) await this.overlayOptIn({ apiKey: this.apiKey, rules: this.optin });
                this.closeModal(this.optin);
            },
            checkFeatureFlag,
            modalLinkHandler,
        },

        i18nOptions: { namespaces: ['modal_content'] },
    };

