import axios from 'axios';
import api from '../../api';

const state = {
    currentTab: '',
    isOpen: false,
};

const getters = {};

const mutations = {
    updateDrawerData (state, payload) {
        Object.keys(payload).forEach((key) => {
            state[key] = payload[key];
        });
    },
};

const actions = {
    async getDrawerData ({ commit }) {
        try {
            const { data: { result } } = await axios.get(`${api.base}/drawer`);
            commit('updateDrawerData', result);
        }
        catch (err) {
            console.error(err);
        }
    },

    /**
     * @function changeTab
     * Change the current tab open in the drawer. The drawer must be open to change the tab
     * @param {string} newTab - Options: eligibleProducts, catalog, extras, restaurants
     */
    changeTab ({ commit, dispatch, state }, newTab) {
        if (state.isOpen || (!state.isOpen && newTab === '')) commit('updateDrawerData', { currentTab: newTab });
        dispatch('notifications/markNewContentIndicatorAsRead', null, { root: true });
    },

    closeDrawer ({ commit }) {
        commit('updateDrawerData', {
            isOpen: false,
            currentTab: '',
        });
    },

    toggleDrawer ({ commit, dispatch, state }) {
        commit('updateDrawerData', { isOpen: !state.isOpen });
        if (state.isOpen) dispatch('changeTab', 'catalog');
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
