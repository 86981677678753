import { createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = {
  key: 2,
  class: "maybe-later"
}
const _hoisted_4 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_t = _resolveDirective("t")

  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay",
    onClick: _cache[5] || (_cache[5] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", {
      id: "dahe-ff-updates-modal",
      class: _normalizeClass(["modal ff-confirmation-modal", $options.checkFeatureFlag('fallPromo2024') ? 'hh-confirmation-modal' : '']),
      role: "dialog",
      "aria-labelledby": "modal-title"
    }, [
      _createElementVNode("h2", {
        id: "modal-title",
        innerHTML: _ctx.$t($props.apiKey + '.confirmation.headline_copy')
      }, null, 8, _hoisted_1),
      _createElementVNode("p", {
        innerHTML: _ctx.$t($props.apiKey + '.confirmation.body_copy')
      }, null, 8, _hoisted_2),
      ($options.checkFeatureFlag('fallPromo2024'))
        ? _withDirectives((_openBlock(), _createElementBlock("button", {
            key: 0,
            onClick: _cache[0] || (_cache[0] = (...args) => ($options.exitToPlay && $options.exitToPlay(...args)))
          }, null, 512)), [
            [_directive_t, 'buttons:play_now']
          ])
        : _createCommentVNode("", true),
      _withDirectives(_createElementVNode("button", {
        class: _normalizeClass($options.checkFeatureFlag('fallPromo2024') ? 'button-white' : ''),
        onClick: _cache[1] || (_cache[1] = (...args) => ($options.exitToFileUpload && $options.exitToFileUpload(...args)))
      }, null, 2), [
        [_directive_t, 'buttons:upload']
      ]),
      (!$options.checkFeatureFlag('fallPromo2024'))
        ? _withDirectives((_openBlock(), _createElementBlock("button", {
            key: 1,
            class: "button-pink",
            onClick: _cache[2] || (_cache[2] = (...args) => ($options.exitToOpenRedeem && $options.exitToOpenRedeem(...args)))
          }, null, 512)), [
            [_directive_t, 'buttons:redeem_points']
          ])
        : _createCommentVNode("", true),
      (!$options.checkFeatureFlag('fallPromo2024'))
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, [
            _createElementVNode("a", {
              href: "",
              onClick: _cache[3] || (_cache[3] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
            }, _toDisplayString(_ctx.$t($props.apiKey + `.confirmation.no_thanks`)), 1)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", null, [
        _createElementVNode("p", {
          class: "disclaimer",
          innerHTML: _ctx.$t($props.apiKey + '.confirmation.disclaimer', { tb2URL: _ctx.app.tb2URL, fall24tb2URL: _ctx.app.fall24tb2Url })
        }, null, 8, _hoisted_4)
      ]),
      _createElementVNode("a", {
        href: "#",
        class: "close-btn",
        title: "Close modal",
        onClick: _cache[4] || (_cache[4] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, _cache[6] || (_cache[6] = [
        _createElementVNode("i", {
          "aria-hidden": "true",
          class: "fa-solid fa-xmark"
        }, null, -1)
      ]))
    ], 2)
  ]))
}