
    import { checkFeatureFlag } from '../feature-flags';
    import BaseModal from './BaseModal';

    export default {
        name: 'FileUploadCompleteModal',

        extends: BaseModal,

        data () {
            return {
                modalName: 'receipt-upload-complete',
            };
        },

        methods: {
            checkFeatureFlag,
        },

        i18nOptions: { namespaces: ['file_upload_complete'] },
    };
