import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot } from "vue"
import _imports_0 from '@public/img/header.svg'
import _imports_1 from '@public/img/dunkin.svg'


const _hoisted_1 = { id: "app" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "wrapper" }
const _hoisted_4 = { "data-e2e": "global-header" }
const _hoisted_5 = {
  id: "global-header",
  tabindex: "-1"
}
const _hoisted_6 = {
  role: "main",
  class: "profile-layout"
}
const _hoisted_7 = {
  key: 0,
  href: "#/hub",
  class: "xmark"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_PromoLinks = _resolveComponent("PromoLinks")
  const _component_MenuBar = _resolveComponent("MenuBar")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.loggedIn)
      ? (_openBlock(), _createElementBlock("nav", _hoisted_2, [
          _createVNode(_component_MenuBar, null, {
            default: _withCtx(() => [
              _createVNode(_component_PromoLinks, { "aria-label": "nav_links" })
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("header", _hoisted_4, [
        _createElementVNode("h1", _hoisted_5, _toDisplayString(_ctx.$t('global:title')), 1),
        _cache[0] || (_cache[0] = _createElementVNode("img", {
          class: "headerimg",
          src: _imports_0,
          alt: "Dunkin' At Home Extras"
        }, null, -1))
      ]),
      _createElementVNode("main", _hoisted_6, [
        _renderSlot(_ctx.$slots, "default", { class: "content" }),
        (_ctx.loggedIn)
          ? (_openBlock(), _createElementBlock("a", _hoisted_7, _cache[1] || (_cache[1] = [
              _createElementVNode("i", {
                "aria-hidden": "true",
                class: "fa-solid fa-xmark"
              }, null, -1)
            ])))
          : _createCommentVNode("", true),
        _cache[2] || (_cache[2] = _createElementVNode("img", {
          class: "dunkinimg",
          src: _imports_1,
          alt: "dunkin"
        }, null, -1))
      ])
    ])
  ]))
}