import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@public/img/promotional_modal/jetBluePromo-header.png'
import _imports_1 from '@public/img/summer_sweepstakes/jetblue_bodycopy.png'


const _hoisted_1 = {
  id: "promo-modal",
  class: "initial-promo modal",
  role: "dialog",
  "aria-labelledby": "modal-title"
}
const _hoisted_2 = ["alt"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["disabled"]
const _hoisted_5 = { class: "maybe-later" }
const _hoisted_6 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BaseCheckbox = _resolveComponent("BaseCheckbox")

  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay",
    onClick: _cache[5] || (_cache[5] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        id: "modal-title",
        src: _imports_0,
        alt: _ctx.$t($props.apiKey + '.initial_promo_modal.headline_copy')
      }, null, 8, _hoisted_2),
      _createElementVNode("p", {
        onClick: _cache[0] || (_cache[0] = (...args) => ($options.modalLinkHandler && $options.modalLinkHandler(...args))),
        innerHTML: _ctx.$t($props.apiKey + '.initial_promo_modal.body_copy')
      }, null, 8, _hoisted_3),
      _cache[7] || (_cache[7] = _createElementVNode("img", {
        class: "jetblue-entries-callout",
        src: _imports_1
      }, null, -1)),
      _createVNode(_component_BaseCheckbox, {
        modelValue: $data.optin,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($data.optin) = $event)),
        name: "overlay-optin",
        label: _ctx.$t($props.apiKey + '.initial_promo_modal.optin', { tb2URL: _ctx.app.tb2URL })
      }, null, 8, ["modelValue", "label"]),
      _createElementVNode("button", {
        disabled: !$data.optin,
        onClick: _cache[2] || (_cache[2] = $event => ($options.enterNow()))
      }, _toDisplayString(_ctx.$t($props.apiKey + '.initial_promo_modal.button_label')), 9, _hoisted_4),
      _createElementVNode("p", _hoisted_5, [
        _createElementVNode("a", {
          href: "",
          onClick: _cache[3] || (_cache[3] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
        }, _toDisplayString(_ctx.$t($props.apiKey + '.initial_promo_modal.no_thanks')), 1)
      ]),
      _createElementVNode("p", {
        class: "disclaimer",
        innerHTML: _ctx.$t($props.apiKey + '.initial_promo_modal.disclaimer', { tb2URL: _ctx.app.tb2URL })
      }, null, 8, _hoisted_6),
      _createElementVNode("a", {
        href: "#",
        class: "close-btn",
        title: "Close modal",
        onClick: _cache[4] || (_cache[4] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, _cache[6] || (_cache[6] = [
        _createElementVNode("i", {
          "aria-hidden": "true",
          class: "fa-solid fa-xmark"
        }, null, -1)
      ]))
    ])
  ]))
}